import React from 'react'

import Layout from '@components/layout/Layout'
import ReadNext from '@components/read-next-button/read-next-button'

import './about.scss'

const About = ({ location }) => {

  const leftList = [
    {
      owner_name: 'Rakesh Bhattacharjee, MD',
      info_one: 'The University of California, San Diego (UCSD) ',
      info_two: 'Department of Pediatrics',
      info_three: 'Division of Pediatric Respiratory Medicine',
      address: 'San Diego, CA',
    },
    {
      owner_name: 'Richard Bogan, MD',
      info_one: 'Bogan Sleep Consultants, LLC',
      info_two: 'SleepMed of South Carolina',
      info_three: 'University of South Carolina School of Medicine',
      address: 'Columbia, SC ',
    },
    {
      owner_name: 'Karl Doghramji, MD',
      info_one: 'Jefferson Sleep Disorders Center',
      info_two: 'Jefferson Health',
      address: 'Philadelphia, PA'
    },
    {
      owner_name: 'Emmanuel Mignot, MD, PhD',
      info_one: 'Stanford Center for Sleep Sciences and Medicine',
      info_two: 'Stanford University',
      address: 'Palo Alto, CA ',
    },
  ]
  const rightList = [
    {
      owner_name: 'Anne Marie Morse, DO',
      info_one: 'Geisinger Commonwealth School of Medicine',
      info_two: 'Geisinger Medical Center',
      info_three: 'Janet Weis Children’s Hospital',
      address: 'Danville, PA',
    },
    {
      owner_name: 'Russell Rosenberg, PhD',
      info_one: 'Neurotrials Research',
      address: 'Atlanta, GA',
    },
    {
      owner_name: 'Thomas Scammell, MD',
      info_one: 'Beth Israel Deaconess Medical Center',
      info_two: 'Harvard Medical School',
      address: 'Boston, MA',
    },
    {
      owner_name: 'Phyllis Zee, MD, PhD',
      info_one: 'Northwestern University Feinberg School of Medicine',
      info_two: 'Northwestern Medicine Sleep Disorders Center',
      info_three: 'Center for Circadian and Sleep Medicine',
      address: 'Chicago, IL',
    },
  ]

  const readNext = {
    readNextContent: 'Hear from the experts',
    goTo: '/resources/narcolepsy-expert-clinical-insights/',
    tracking: 'about narcolepsy link, click,	what causes narcolepsy - about narcolepsy link'
  }

  return (
    <Layout addedClass='page-about-narcolepsy' location={location}>
      <div className="primary-container">
        <h1 className="main-page-header">About NarcolepsyLink</h1>
        <p className="page-comment">
          Our mission is to increase narcolepsy awareness, help patients receive a proper diagnosis of narcolepsy, and
          to support long-term disease management. NarcolepsyLink creates innovative, evidence-based educational
          programs, materials, and resources for healthcare professionals to improve communication between healthcare
          professionals and their patients.
        </p>
      </div>
      <div className="feature-wrapper">
        <div className="primary-container">
          <div className="feature-comment">
            The NarcolepsyLink Steering Committee comprises sleep experts from varying areas of clinical practice,
            including sleep medicine, neurology, psychiatry, and pulmonology. The members of the steering committee
            are paid consultants of Jazz Pharmaceuticals.
          </div>
          <div className="steering-committee-title">Steering Committee</div>
          <div className="steering-committee-list">
            <div className="left-container">
              {leftList.map((item, index) => {
                return (
                  <div className="steering-committee-item" key={index}>
                    <div className="item-info item-owner">{item.owner_name}</div>
                    <div className="item-info">{item.info_one}</div>
                    {item.info_two && <div className="item-info">{item.info_two}</div>}
                    <div className="item-info">{item.info_three}</div>
                    <div className="item-info">{item.address}</div>
                  </div>
                )
              })}
            </div>
            <div className="right-container">
              {rightList.map((item, index) => {
                return (
                  <div className="steering-committee-item" key={index}>
                    <div className="item-info item-owner">{item.owner_name}</div>
                    <div className="item-info">{item.info_one}</div>
                    {item.info_two && <div className="item-info">{item.info_two}</div>}
                    <div className="item-info">{item.info_three}</div>
                    <div className="item-info">{item.address}</div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='margin-top-30'/>
          <ReadNext readNext={readNext}/>
        </div>
      </div>
    </Layout>
  )
}

export default About
